<template>
	<div class="container">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'users-index'}">Users</router-link>
				</li>
			</ol>
		</nav>

		<vue-good-table
				:is-loading="loading"
				:columns="columns"
				:rows="agents" class="mb-3" @on-row-click="openUserDetails">
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field == 'Username'">
				  <agent-card :agent="props.row"></agent-card>
				</span>
				<span v-else>
				  {{props.formattedRow[props.column.field]}}
				</span>
			</template>
		</vue-good-table>

		<router-link :to="{name: 'users-new'}" class="btn btn-primary" v-if="!loading && canWrite('users')">
			<i class="fa fa-plus mr-2"></i>
			Add user
		</router-link>

	</div>
</template>

<script>
	import api from '@/api'
	import AgentCard from "../../components/agents/agent-card";

	export default {
		components: {AgentCard},
		data() {
			return {
				agents: [],
				loading: false,
				columns: [
					{
						label: 'Username',
						field: 'Username',
					},
					{
						label: 'Email',
						field: 'Email',
					},
					{
						label: 'Created On',
						field: 'CreatedAt',
					},
					/*
				{
					label: 'Percent',
					field: 'score',
					type: 'percentage',
				},

					 */
				],
			}
		},
		mounted() {
			this.refreshUsers()
		},
		methods: {
			openUserDetails(e) {
				this.$router.push({
					name: "users-edit-user_id",
					params: {
						user_id: e.row.Id
					}
				})
			},
			async refreshUsers() {
				this.loading = true
				this.agents = await api.Agents.getList().finally(() => this.loading = false)
			}
		}
	}
</script>
